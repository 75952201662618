
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code2 } from "./data";

export default defineComponent({
  name: "inline-form",
  data() {
    return {
      formInline: {
        user: "",
        region: ""
      }
    };
  },
  components: {
    CodeHighlighter
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    }
  },
  setup() {
    return {
      code2
    };
  }
});
